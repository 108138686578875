
.contactContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns:  repeat(2, 1fr);
    grid-template-rows: 60px 500px;;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    background-color: #fcfcfc;
    padding: 50px;
  
}

.mapContainer {
    grid-column: 2 / 3;


}

.contactInfo {
    grid-column: 1 / 2;
    display: flex;
}

.contactlistDiv {
margin-top: 50px;
list-style-position: outside;
}

.contactlistDiv ul {
    list-style-position: inside;
    }
    



.contactTitle {
    grid-row: 1;
    grid-column: span 2;;
    display: flex;
    justify-content: center
}


@media screen and (max-width: 1200px) {
    .contactContainer {
        grid-template-columns: 1fr;
        grid-template-rows: 60px 150px 500px;;
        grid-row-gap: 20px;
        background-color: #fcfcfc;
      
    }

    .contactlistDiv {
        grid-column: 1;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    
    .mapContainer {
        grid-column: 1;
        grid-row: 3;
        padding-left: 20px;

    
    }

}
