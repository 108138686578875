.layoutContainer {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 0.5fr repeat(4, 1fr) 0.5fr;
    grid-template-rows: 150px auto 200px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    background-color: #fcfcfc;
    margin-bottom: 0px;
  
  }

  .layoutHeaderContainer {
    grid-column: 1 / 7;
    grid-row: 1;
    display: grid;
    grid-template-columns:  repeat(6, 1fr);
    grid-template-rows: auto;
    position: sticky;
    top: 0;
    background-color: inherit;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    z-index: 10;
  }

  .layoutHeader {
      grid-column: 2 / 6;
      grid-row: 1;
  }

  .layoutMain {
    grid-column: 2 / 6;
    grid-row: 2;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 5px;;

  }

  .layoutLeft {
    grid-column: 1 / 2;
    grid-row: 2 / 6;

  }

  .layoutRight {
    grid-column: 6 / 7;
    grid-row: 2 / 6;

  }

  .layoutFooter {
    grid-column: 2 / 6;
    grid-row: 3;

  }

  @media screen and (max-width: 700px) {
    .layoutContainer {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 100px auto 200px;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      background-color: #fcfcfc;
    
    }
  
    .layoutHeaderContainer {
      grid-column: 1;
      grid-row: 1;
      display: grid;
      grid-template-columns:  1fr;;
      grid-template-rows: repeat(5, 1fr) auto;
      position: sticky;
      top: 0;
      background-color: inherit;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      z-index: 10;
    }
  
    .layoutHeader {
        grid-column: 1;
        grid-row: 1;
    }
  
    .layoutMain {
      grid-column: 1;
      grid-row: 2;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      border-radius: 5px;;
  
    }
  
    .layoutLeft {
      display: none;
    }
  
    .layoutRight {
      display: none;

  
    }
  
    .layoutFooter {
      grid-column: 1;
      grid-row: 3;
  
    }

  }