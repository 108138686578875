.headerContainer{
    display: flex;
    width: 100%;
    height: 100%;
    position: sticky;;
    top:0;
    border-bottom: 1px solid #ececec;
    background-color: inherit;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.headerMenu {
    width: 30%;
    height: auto;
    border: none;
    position: relative;
    margin-right: 20px;
    width: auto;
    padding-bottom: 3px;
    display: flex;
    text-decoration: none;

}

.headerLogo {
    width: 40%;
    position: relative;
    margin: 0px 0px 0px 20px;

}

.headerLogo {
    margin: 0;
    width: auto;
    max-width: 220px;
}

.headerContainer a {
    text-decoration: none;
}

.mobileHeaderContainer {
    display: none
}

.mobileHeaderLogo {
    display: none;
}

@media screen and (max-width: 700px) {
    .mobileHeaderContainer {
        display: grid;
        width: 100%;
        height: 100%;
        grid-template-columns: 10% 70% 10%;
        grid-template-rows: 100%;;
        justify-content: center;
        align-items: center;
    } 

    .headerContainer {
        display: none;
    }

    .mobileHeaderLogo {
        max-width: 200px;
        margin-top: 15px;
        z-index: 2000;

    }

    .mobileHeaderLogoContainer { 
        grid-column: 2;

        width: 100%;
        display: flex;
        justify-content: center;
    }

    .mobileHeaderLogo {
        display: block;
        max-width: 200px;

    }

    .mobileHeaderMenuIcon {
        max-width: 40px;
        margin-right: 0;;
    }

}