.mainLayout {
  width: 80%;
  min-width: 500px;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;


}

.mainHeader {
  width: 100%;
  margin: 0px 0px 0px 0px;
	position: -webkit-sticky; /* Safari */
	position: sticky;
	top: 0;

}

main {
    width: 100%;
    padding: 0% 0% 0% 0%;
    font-size: 0.9rem;
    line-height: 1.6;

}
