.mobileNavButton {
    display: flex;
    background-color: white;
    color: #000000;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 3rem;
    /* border-bottom: 1px solid white; */
    border-left: 1px solid white;
    border-right: 1px solid white;

    box-shadow: none;
    padding-bottom: 5px;
    transition: 0.6s;
    justify-content: center;
    align-items: center;
    background-color: inherit;


}

.mobileNavButton:hover {
    padding-bottom: none;
    border-right: 40px solid rgb(194, 122, 122);
    border-left: 40px solid rgb(194, 122, 122);
}