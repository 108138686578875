.footerLinksContainer{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns:  repeat(5, 1fr);
    grid-template-rows: auto 20px auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    background-color: inherit;;
    margin-top: 20px;
}

.linksContainerLeft {
    grid-column: 2;
    display: flex;
    flex-flow: column wrap;
    height: 100%;
    color: #000000;
    flex-wrap: wrap

}

.linksContainerRight {
    grid-column: 4;
    display: flex;
    flex-flow: column wrap;
    height: 100%;
    color: #000000;
    flex-wrap: wrap

}

.footerLink {
    color: #747474;
    font-size: 0.9rem;;
}

.footerLink:hover {
    color: #000000;
}

.footerContainer a {
    text-decoration: none;
}

.footerInfo {
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;;
    color: #717171
}

.footerLinksContainerMobile {
    display: none;
}

@media screen and (max-width: 700px) {

    .footerLinksContainer{
        display: none;
    }

    .footerInfo {
        display: none;
    }
    
    .footerLinkMobile {
        color: #747474;
        font-size: 0.9rem;
        }

    .footerInfoMobile {
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 12px;;
            color: #717171
    }
 

    .footerLinksContainerMobile {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: min-content;
        grid-column-gap: 20px;
        background-color: #fcfcfc;
        place-items: center;
      
    }
}