.jumbotronContainer {
    width: 100%;
    height: 250px;
    background: rgb(20,47,47);
    background: linear-gradient(0deg, rgba(20,47,47,1) 0%, rgba(7,0,36,1) 100%);
    border-top-left-radius: 5px;;
    border-top-right-radius: 5px;;


}

.jumbotronContainer h1 {
    font-size: 2.5rem;
    color: #ffffff;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 600;
    letter-spacing: 3px;
    padding: 5% 0% 0% 10%;
}

.jumbotronContainer p {
    font-size: 14px;
    color: #ffffff;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 300;
    padding: 0% 10% 0% 10%;
}

.jumbotronContainerBtn {
    width: 10rem;
    height: 3rem;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 300;
    margin: 0% 10% 0% 10%;
    background-color: #892131;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
    transition: 0.3s;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

}

.jumbotronContainerBtn:hover {
    background-color: #84303c;


}

@media screen and (max-width: 510px) {
    .jumbotronContainer {
        height: 280px;
    }

    .jumbotronContainerBtn {
        font-size: 15px;

    }

    
}